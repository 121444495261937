import { Card, CardBody, CardHeader, Col, Row } from "react-bootstrap"

const CollectionTabs = ({transactionHistory}) => {
	const transactionHistoryData = {
		'Total collection': {
			total_amount: transactionHistory.reduce( (total, item) => total + (item.total_amount-item.discount), 0 ),
			color: "primary"
		},
		'Collection in Cash': {
			total_amount: transactionHistory.filter(item => item.payment_mode == "cash").reduce( (total, item) => total + (item.total_amount-item.discount), 0 ),
			color: "success"
		},
		'Collection in UPI': {
			total_amount: transactionHistory.filter(item => item.payment_mode == "upi").reduce( (total, item) => total + (item.total_amount-item.discount), 0 ),
			color: "success"
		},
		'Collection in Cheque': {
			total_amount: transactionHistory.filter(item => item.payment_mode == "cheque").reduce( (total, item) => total + (item.total_amount-item.discount), 0 ),
			color: "warning"
		},
		'Online Payment': {
			total_amount: transactionHistory.filter(item => item.payment_mode == "pg").reduce( (total, item) => total + (item.total_amount-item.discount), 0 ),
			color: "warning"
		},
		'Other modes': {
			total_amount: transactionHistory.filter(item => item.payment_mode != "cash" && item.payment_mode != "upi" && item.payment_mode != "cheque" && item.payment_mode != "pg").reduce( (total, item) => total + (item.total_amount-item.discount), 0 ),
			color: "danger"
		}
	}
	return (
		<Row className="mb-3">
			{Object.keys(transactionHistoryData).map((key) => (
				<Col key={key} xs={6} lg={4} className="">
					<Card>
						<CardHeader>
							<h5 className={`text-${transactionHistoryData[key].color} card-title mb-0 fs-6 fs-md-5`}>{key}</h5>
						</CardHeader>
						<CardBody>
							<h1 className="">&#8377;&nbsp;{transactionHistoryData[key].total_amount}</h1>
						</CardBody>
					</Card>
				</Col>
			))}
		</Row>
	);
};

export default CollectionTabs;
